import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Menu from "../components/menu";

// markup
export default function Hero() {
  return (
    <div className="hero" id="hero">
      <Menu />
      <div className="logo_big"></div>
      <h1 className="title">
        Vývoj a provoz informačních systémů a prostředků hromadného zpracování
        dat
      </h1>
      <AnchorLink className="super_button" to="/#sectionAbout">
        <h1>Více o společnosti</h1>
      </AnchorLink>
    </div>
  );
}
