import * as React from "react";

// markup
export default function Footer() {
  return (
    <>
      <div className="sectionFooter" id="sectionFooter">
        <div className="color"></div>
      </div>
      <div className="sectionFooterBottom">
        <div className="all-info">
          <div className="info">
            <div className="mail">
              <div className="icon"></div>
              <a href="mailto:info@bsptechnology.eu">
                <div className="text">info@bsptechnology.eu</div>
              </a>
            </div>
            <div className="phone">
              <div className="icon"></div>
              <a href="tel:+420226230210">
                <div className="text">+420 226 230 210</div>
              </a>
            </div>
          </div>
          <div className="info2">
            <div className="adress">
              <div className="icon"></div>
              <div className="moreText">
                <div className="text">BSP Technology s.r.o.</div>
                <div className="text">Politických vězňů 1272/21</div>
                <div className="text">Praha - Nové Město</div>
                <div className="text">11000</div>
              </div>
            </div>
          </div>
          <div className="info3">
            <div className="adress">
              <div className="icon"></div>
              <div className="moreText">
                <div className="text">BSP Technology s.r.o.</div>
                <div className="text">Politických vězňů 1272/21</div>
                <div className="text">Praha - Nové Město</div>
                <div className="text">11000</div>
                <div className="text">IČO: 24675008</div>
                <div className="text">DIČ: CZ24675008</div>
              </div>
            </div>
          </div>
        </div>
        <div className="all-info">
          <div className="info3">
            <div className="adress">
              <div className="icon"></div>
              <div className="moreText">
                <div className="text">Zápis v OR:</div>
                <div className="text">spisová značka C 165223</div>
                <div className="text">vedená u Městského soudu v Praze</div>
              </div>
            </div>
          </div>
          <div className="info4">
            <div className="adress">
              <div className="icon"></div>
              <div className="moreText">
                <div className="text">Bankovní spojení:</div>
                <div className="text">Účet CZK</div>
                <div className="text">5011036247/5500</div>
              </div>
            </div>
          </div>
          <div className="info4">
            <div className="adress">
              <div className="icon"></div>
              <div className="moreText">
                <div className="text">IBAN:</div>
                <div className="text">CZ32 5500 0000 0050 1103 6247</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
