import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// markup
export default function About() {
  return (
    <div className="sectionAbout" id="sectionAbout">
      <div className="about">
        <h1>O společnosti</h1>
        <h3>
          Společnost BSP Technology s.r.o. se dlouhodobě specializuje na{" "}
          <span className="dark">
            vývoj a provoz informačních systémů a prostředků hromadného
            zpracování dat,
          </span>
          a to zejména ve dvou oblastech.
        </h3>
        <br />
        <br />
        <br />
        <div className="areas">
          <AnchorLink className="areaA" to="/#sectionTelematics">
            <div className="icon"></div>
            <div className="text">
              <div className="header">telematika/logistika</div>
              <div className="under-header">
                TMS, přestupky, trasování, objízdné trasy
              </div>
            </div>
          </AnchorLink>
          <div className="line"></div>
          <AnchorLink className="areaB" to="/#sectionService">
            <div className="icon"></div>
            <div className="text">
              <div className="header">řízení a správa pohledávek</div>
              <div className="under-header">
                či obdobného klientského portfolia
              </div>
            </div>
          </AnchorLink>
        </div>
      </div>
    </div>
  );
}
