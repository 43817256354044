import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// markup
export default function Menu() {
  return (
    <div className="menu">
      <div className="container">
        <div className="controls">
          <AnchorLink to="/#hero" className="logo" />
          <AnchorLink
            to="/#sectionTelematics"
            className="button"
            title="Telematika"
          />
          <AnchorLink
            to="/#sectionService"
            className="button"
            title="Pohledávky"
          />
          <AnchorLink
            to="/#sectionFooter"
            className="button"
            title="Kontakty"
          />
        </div>
      </div>
    </div>
  );
}
