import * as React from "react";
import "../styles/styles.scss";
import "../styles/colors.scss";
import Hero from "../components/hero";
import About from "../components/about";
import Telematics from "../components/telematics";
import Service from "../components/service";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

export default function IndexPage() {
  return (
    <div className="web">
      <Helmet>
        <meta charSet="utf-8" />
        <title>BSP Technology</title>
        <link rel="canonical" href="http://www.bsptechnology.eu" />
      </Helmet>
      <Hero />
      <About />
      <Telematics />
      <Service />
      <Footer />
    </div>
  );
}
