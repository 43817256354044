import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// markup
export default function Service() {
  return (
    <div className="sectionService" id="sectionService">
      <div className="about">
        <h1>Správa pohledávek – pohledávkový servis</h1>
        <h3>
          Společnost BSP Technology se na straně druhé věnuje{" "}
          <span className="dark">správě a vymáhání pohledávek,</span> a to
          zejména v rovině{" "}
          <span className="dark">
            hromadného vymáhání velkého počtu individuálních klientských
            pohledávek{" "}
          </span>
          pro státní, veřejnoprávní i soukromé instituce.
        </h3>
        <h3>
          Zajišťujeme <span className="dark">kompletní servis,</span> tedy od
          kontinuálního{" "}
          <span className="dark">
            vývoje vlastního informačního systému pro správu pohledávek
          </span>{" "}
          či jednotlivých právních případů, přes{" "}
          <span className="dark">
            inkasní řízení, soudní vymáhání pohledávky až po podání a dohled nad
            exekučním řízením.{" "}
          </span>
          Agendu převezmeme v jakékoliv fázi, a následně zabezpečíme správu
          Vašeho portfolia dle Vašich požadavků, a to jak jednorázově, tak
          kontinuálně.
        </h3>
        <br />
        <br />
        <br />
        <div className="areas">
          <div className="header">
            <div className="icon"></div>
            <h3 className="text-header">IS pro hromadnou správu pohledávek</h3>
          </div>
          <div className="text">
            Disponujeme unikátním technologickým řešením -{" "}
            <span className="dark">
              vlastním informačním systémem pro hromadnou správu pohledávek,
            </span>{" "}
            který dokáže díky automatizovaným procesům a napojením na desítky
            externích systémů, plně a 
            <span className="dark">
              účinně řídit životní cyklus jednotlivých případů.{" "}
            </span>
            Tento IS lze plně uživatelsky přizpůsobit{" "}
            <span className="dark">zadání klienta</span> z hlediska, ať již
            z hlediska nastavení procesů, lhůt, šablon, struktury pohledávky,
            dokumentů či reportů. Systém je pomocí zabezpečeného přenosu
            <span className="dark">
              v běžném webovém prohlížeči dostupný prakticky odkudkoliv,
            </span>{" "}
            a je na základě řízení přístupových práv využíván jak advokátními
            kancelářemi, tak i operátory call-centra, exekutory či
            administrátory klientů.
          </div>
        </div>
        <div className="areas">
          <div className="header">
            <div className="icon2"></div>
            <h3 className="text-header">
              Mimořádně efektivní vymožení pohledávky
            </h3>
          </div>
          <div className="text">
            BSP Technology úzce spolupracuje s dlouhodobě prověřenými{" "}
            <span className="dark">
              advokátními kancelářemi a exekutorskými úřady v rámci celé ČR,
            </span>{" "}
            a tak je v kombinaci s dlouholetými zkušenostmi, možno dosáhnout
            mimořádně efektivního vymožení pohledávky ve velmi krátkém čase.
          </div>
        </div>
        <div className="areas">
          <div className="header">
            <div className="icon3"></div>
            <h3 className="text-header">Naši klienti</h3>
          </div>
          <div className="text">
            Mezi naše hlavní klienty patří velké organizace zajišťující
            veřejnoprávní služby pro milióny odběratelů, bankovní instituce
            poskytující podnikatelské a spotřebitelské úvěry, leasingové
            společnosti, dodavatelé elektřiny a plynu apod.
          </div>
        </div>
        <div className="areas">
          <div className="header">
            <div className="icon4"></div>
            <h3 className="text-header">
              Analýza procesů, pohledávek, návrh řešení
            </h3>
          </div>
          <div className="text">
            Provedeme analýzu Vašeho portfolia pohledávek, a navrhneme
            nejvhodnější postup vymáhání jednotlivých případů tak, aby vymožení
            dluhu bylo co možná nejrychlejší a nejefektivnější.
          </div>
          <br />
          <br />
          <div className="text">
            V případě kontinuální či opakované spolupráce naši specialisté
            připraví nejjednodušší variantu řešení automatizované komunikace
            mezi oběma informačními systémy, tedy individuální přizpůsobení IS
            dle Vašich požadavků, resp. struktury Vašich pohledávek (skladba
            dluhu, úročení apod.), dostupných dokumentů (import faktur, upomínek
            apod.), procesních limitů a lhůt, požadovaných reportů apod.
          </div>
        </div>
        <div className="areas">
          <div className="header">
            <div className="icon5"></div>
            <h3 className="text-header">Upomínkové řízení</h3>
          </div>
          <div className="text">
            Převezmeme za Vás kompletní agendu upomínkového řízení či její
            části. Díky unikátnímu řešení řízení cyklu pohledávkových případů
            dokážeme zasílat Vašim klientům v přesně daných lhůtách písemné,
            emailové i SMS upomínky připravené dle Vašich šablon, jakožto
            i generovat upomínkové faktury či předpisy. Naše vlastní
            call-centrum může současně obsluhovat veškerou příchozí komunikaci
            dlužníků (dle zadání).
          </div>
        </div>
        <div className="areas">
          <div className="header">
            <div className="icon6"></div>
            <h3 className="text-header">
              Inkasní řízení - mimosoudní vymáhání pohledávky
            </h3>
          </div>
          <div className="text">
            Naší hlavní prioritou je mimosoudní vymožení
            pohledávky prostřednictvím přímé i nepřímé komunikace s dlužníky.
            Systémově zajistíme automatický import případů, ať již jednorázově z
            iniciálních dat, anebo pomocí námi vytvořeného importního můstku pro
            konkrétního uživatele. Automaticky provedeme lustraci dlužníků
            a ihned zahájíme vymáhání dle nastavených kritérií.
          </div>
          <br />
          <br />
          <div className="text">
            Dlužníci mohou být upomínáni písemnými, emailovými a SMS výzvami dle
            odsouhlasených vzorů, a to v přesně stanovaných termínech. Naše
            vlastní call-centrum volitelně zajistí celodenní telefonické
            obvolávání dlužníků, včetně záznamu komunikace. Centrum
            administrativní podpory dále zpracovává veškerou příchozí a odchozí
            komunikaci ve spolupráci s právním oddělením.
          </div>
          <br />
          <br />
          <div className="text">
            V závislosti na typu pohledávky, skladby dlužníků a lhůt (délky)
            inkasního řízení, dosahujeme dlouhodobě 40-85% vymahatelnosti
            z předaného portfolia klientských pohledávek.
          </div>
        </div>
        <div className="areas">
          <div className="header">
            <div className="icon7"></div>
            <h3 className="text-header">Soudní vymáhání pohledávky</h3>
          </div>
          <div className="text">
            Zajistíme soudní vymáhání pohledávek vzniklých ze smluv, směnečných
            smluv nebo smluv uzavřených dálkově.
          </div>
          <br />
          <br />
          <div className="text">
            Pohledávky okamžitě připravíme pro plně automatizované podání
            k místně příslušným soudům. Vždy provedeme automatickou
            lustraci jednotlivých dlužníků a případy vhodné k podání žaloby
            následně přidělíme advokátním kancelářím.
          </div>
          <br />
          <br />
          <div className="text">
            Žaloby jsou podány automaticky, nejčastěji ve formě Návrhu na vydání
            platebního rozkazu s relevantními přílohami. Souběžně je zpracována
            odpověď a vygenerován hromadný příkaz k úhradě soudních poplatků.
          </div>
          <br />
          <br />
          <div className="text">
            Spolupracující advokáti následně zabezpečují komplexní
            zastupování v soudním řízení, účast na soudním jednání, komunikaci
            se soudy apod. IS zajišťuje vedení elektronického spisu případu
            a komunikaci se soudy, advokáty a dlužníky prostřednictvím datových
            schránek, emailů, SMS zpráv a dálkově distribuované pošty.
          </div>
        </div>
        <div className="areas">
          <div className="header">
            <div className="icon8"></div>
            <h3 className="text-header">Exekuční řízení</h3>
          </div>
          <div className="text">
            Zajistíme plně automatizované podání a předání případu jednotlivým
            spolupracujícím exekučním úřadům. Náš informační systém a IS
            exekutorských úřadů jsou propojeny moduly se zabezpečeným přenosem
            dat a oboustranně si předávají informace i jednotlivé dokumenty.
            Spolupracující exekutoři nepožadují zálohy na provedení výkonu
            exekuce a ve většině případů ani náhrady nákladů exekučního řízení
            v případě marné exekuce.
          </div>
        </div>
      </div>
    </div>
  );
}
