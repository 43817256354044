import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// markup
export default function Telematics() {
  return (
    <div className="sectionTelematics" id="sectionTelematics">
      <div className="color">
        <div className="about">
          <h1>Telematika & logistika</h1>
          <h3>
            Společnost BSP Technology má dlouholetou zkušenost s vývojem a
            provozováním vlastních informačních systémů{" "}
            <span className="dark">v oblasti telematiky a logistiky,</span>{" "}
            zahrnující zejména následující oblasti.
          </h3>
          <br />
          <br />
          <br />
          <div className="areas-box">
            <div className="areas">
              <div className="areaA">
                <div className="icon"></div>
                <div className="under-header">
                  zpracování mapových zdrojů a geolokačních dat
                </div>
              </div>
            </div>
            <div className="areas">
              <div className="areaA">
                <div className="icon2"></div>
                <div className="under-header">
                  vizuální <span className="dark">grafické znázornění</span>{" "}
                  realizovaných či plánovaných tras jednotlivých vozidel
                </div>
              </div>
            </div>
          </div>
          <div className="areas-box">
            <div className="areas">
              <div className="areaA">
                <div className="icon3"></div>
                <div className="under-header">
                  <span className="dark">výpočty a optimalizace trasy</span>{" "}
                  různých kategorií vozidel (osobní a nákladní)
                </div>
              </div>
            </div>
            <div className="areas">
              <div className="areaA">
                <div className="icon4"></div>
                <div className="under-header">
                  <span className="dark">rekonstrukce pohybu vozidel</span> na
                  základě GPS značek nebo transakcí dopravních systémů
                </div>
              </div>
            </div>
          </div>
          <div className="areas-box">
            <div className="areas">
              <div className="areaA">
                <div className="icon5"></div>
                <div className="under-header">
                  <span className="dark">kalkulace mýtného</span> či obdobných
                  poplatků
                </div>
              </div>
            </div>
            <div className="areas">
              <div className="areaA">
                <div className="icon6"></div>
                <div className="under-header">
                  analýza tzv. „objízdných tras“ na komunikacích ČR
                </div>
              </div>
            </div>
          </div>
          <h3>
            BSP Technology je několik posledních let výhradním zpracovatelem
            incidentů v mýtném systému (mikrovlnný mýtný systém). Pomocí
            telematické analýzy "velkých dat" transakcí jsou aktivně vyhledávány
            přestupky jednotlivých dopravců, a následně po rekonstrukci skutečné
            trasy vozidel jsou zpětně dopočteny nepředepsané mýtné poplatky.
          </h3>
          <div className="button-section">
            <AnchorLink className="button" to="/#sectionFooter">
              <h1>Kontaktovat společnost</h1>
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
}
